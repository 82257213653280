<template>
  <v-container>
    <div class="d-flex align-center mb-5">
      <QInput v-model="q" />
      <v-btn
        color="green"
        class="ml-10"
        dark
        @click="
          newProduct.data = newProductBase();
          newProduct.show = true;
        "
      >
        <v-icon>mdi-plus</v-icon>
        Add product
      </v-btn>
    </div>
    <div class="d-flex mb-5">
      <v-checkbox
        v-model="filters.onlyAdded"
        label="Only added"
        hide-details
        class="mt-0"
      />
      <v-checkbox
        v-model="filters.withoutBarcode"
        label="Without barcore"
        hide-details
        class="mt-0 ml-3"
      />
      <v-checkbox
        v-model="filters.notFromThor"
        label="Added on this page"
        hide-details
        class="mt-0 ml-3"
      />
      <v-checkbox
        v-model="filters.withHidden"
        label="Show also hidden"
        hide-details
        class="mt-0 ml-3"
      />
    </div>
    <div v-if="filterShelf">
      Selected shelf:
      <v-chip :to="{ query: { shelf: undefined } }">
        {{ filterShelf.name }}
        <v-icon>mdi-close</v-icon>
      </v-chip>
    </div>
    <div>
      <v-simple-table v-if="filteredProducts.length > 0">
        <thead>
          <tr>
            <th></th>
            <th v-if="filters.withHidden">Hidden</th>
            <th></th>
            <th>Product name</th>
            <th>Model</th>
            <th>PN</th>
            <th>Barcodes</th>
            <th>Qty</th>
            <th>Shelfs</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in filteredProductsPage" :key="product.id">
            <td>
              <div class="d-flex align-center">
                <v-checkbox
                  v-model="selectedProducts"
                  multiple
                  :value="product"
                />
                <span>{{ product.lp }}.</span>
              </div>
            </td>
            <td v-if="filters.withHidden">
              <v-checkbox
                v-model="product.hidden"
                @change="changeProduct(product, 'hidden')"
              />
            </td>
            <td @click="showProduct(product)">
              <v-img
                :src="product.thumbnail"
                width="100px"
                aspect-ratio="2"
                contain
              />
            </td>
            <td @click="showProduct(product)">{{ product.pName }}</td>
            <td @click="showProduct(product)">
              <v-chip
                v-if="product.mName"
                color="pink"
                dark
                class="font-weight-black"
                small
              >
                {{ product.mName }}
              </v-chip>
            </td>
            <td>
              <v-chip outlined small @click="changePn(product)">
                {{ product.pn }}
                <v-icon v-text="'mdi-pencil'" class="ml-1" x-small />
              </v-chip>
            </td>
            <td class="text-no-wrap">
              <div class="d-flex align-center">
                <div
                  @click="
                    changeBarcode.product = product;
                    changeBarcode.show = true;
                  "
                >
                  <div v-if="product.eans.length > 0">
                    <div v-for="ean in product.eans" :key="ean.id">
                      <v-chip
                        small
                        :color="ean.eanGenerated ? 'green' : 'grey'"
                        style="cursor: pointer"
                        >{{ ean.name ? ean.name + ": " : ""
                        }}{{ ean.ean }}</v-chip
                      >
                    </div>
                  </div>
                  <div v-else style="cursor: pointer">n / a</div>
                </div>
                <v-btn
                  icon
                  x-small
                  class="ml-2"
                  @click="
                    selectedProducts = [product];
                    showPrintDialog();
                  "
                >
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </div>
            </td>
            <td>
              {{ product.shelfs.reduce((sum, s) => sum + s.qty, 0) }}
            </td>
            <td>
              <v-chip
                v-for="(shelf, sk) in product.shelfs"
                :key="sk"
                small
                @click="
                  showProduct(product);
                  productDialog.scannedShelf = shelf.barcode;
                "
              >
                <ShelfName
                  :shelf="allShelfs.filter(x => x.id === shelf.id)[0]"
                />
                <v-badge :content="shelf.qty + ''" inline />
              </v-chip>
            </td>
            <td>
              <v-btn icon @click="showProduct(product)">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                v-if="!product.fromThor"
                icon
                color="red"
                @click="removeProduct(product)"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-alert v-else color="blue" dark
        >There are no products matching your criteria</v-alert
      >
    </div>
    <div class="d-flex align-center">
      <div v-if="selectedProducts.length > 0">
        <v-btn @click="showPrintDialog()">
          <v-icon>mdi-printer</v-icon>
          Print barcodes
        </v-btn>
      </div>
      <v-spacer />
      <v-pagination v-model="page" :length="pages" total-visible="10" />
    </div>
    <v-dialog
      v-if="changeBarcode.product"
      v-model="changeBarcode.show"
      persistent
      max-width="600px"
      eager
    >
      <v-toolbar>
        <v-toolbar-title
          >{{
            changeBarcode.product.pName +
              " " +
              (changeBarcode.product.mName || "")
          }}
          barcodes</v-toolbar-title
        >
        <v-spacer />
        <v-btn icon @click="changeBarcode.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-sheet class="pa-5">
        <v-simple-table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Barcode</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(ean, eanK) in changeBarcode.product.eans" :key="eanK">
              <td>
                <v-text-field v-model="ean.name" hide-details dense outlined />
              </td>
              <td>
                <v-text-field
                  v-model="ean.ean"
                  hide-details
                  dense
                  outlined
                  @change="ean.eanGenerated = false"
                />
                <div class="d-flex my-1">
                  <v-btn
                    @click="getRandomEan(ean)"
                    x-small
                    depressed
                    color="blue"
                    outlined
                    class="mr-2"
                    ><v-icon small>mdi-arrow-down</v-icon> get from list</v-btn
                  >
                  <v-btn
                    @click="
                      ean.ean = generateEan();
                      ean.eanGenerated = false;
                    "
                    x-small
                    depressed
                    color="orange"
                    outlined
                    ><v-icon small>mdi-cog</v-icon> generate random</v-btn
                  >
                </div>
              </td>
              <td>
                <v-btn @click="changeBarcode.product.eans.splice(eanK, 1)" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-btn
          small
          @click="
            changeBarcode.product.eans.push({
              name: '',
              ean: '',
              eanGenerated: false
            })
          "
          >+ add new barcode</v-btn
        >
        <div class="d-flex">
          <v-spacer />
          <v-btn @click="changeBarcodeSave()" color="green"
            >Save barcodes</v-btn
          >
        </div>
      </v-sheet>
    </v-dialog>
    <v-dialog v-model="printDialog" max-width="400px">
      <v-toolbar>
        <v-toolbar-title>Print labels</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="printDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-sheet class="pa-5">
        <div v-for="(sp, spk) in selectedProducts" :key="sp.id">
          <div class="font-weight-black">{{ spk + 1 }}. {{ sp.pName }}</div>
          <v-simple-table>
            <tbody>
              <tr v-for="(ean, eank) in sp.eans" :key="eank">
                <td>{{ ean.ean }}</td>
                <td>
                  <v-text-field
                    type="number"
                    min="1"
                    v-model="ean.printQty"
                    solo
                    flat
                    outlined
                    dense
                    hide-details
                  />
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
        <v-btn @click="prnt()">Print</v-btn>
      </v-sheet>
    </v-dialog>
    <v-dialog v-model="productDialog.show" max-width="900px">
      <div v-if="productDialog.product">
        <v-btn depressed color="blue darken-3" dark>Product selected</v-btn>
        <v-btn
          v-if="productDialogScannedShelf"
          depressed
          color="green darken-3"
          dark
          class="ml-3"
          @click="productDialog.scannedShelf = null"
        >
          Shelf selected: <ShelfName :shelf="productDialogScannedShelf" />
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar flat>
          <v-toolbar-title>
            {{
              productDialog.product.pName +
                " " +
                (productDialog.product.mName || "")
            }}
            <v-chip v-if="productDialog.scannedQty > 0"
              >Qty diff: {{ productDialog.scannedQty }}</v-chip
            >
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="productDialog.show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-sheet class="d-flex px-10">
          <v-img
            :src="productDialog.product.thumbnail"
            max-width="120px"
            max-height="100px"
            aspect-ratio="1"
            contain
            class="mr-10"
          />
          <div class="flex-grow-1">
            <div class="d-flex justify-space-between">
              <div>
                <strong>PN:</strong>
                {{ productDialog.product.pn }}
              </div>
              <div>
                <strong>BUY PRICE:</strong>
                {{
                  productDialog.product.buyPrice
                    ? "$" + productDialog.product.buyPrice.toFixed(2)
                    : "n/a"
                }}
                <v-btn
                  icon
                  x-small
                  @click="changeBuyPrice(productDialog.product)"
                  ><v-icon small>mdi-pen</v-icon></v-btn
                >
              </div>
              <div>
                <strong>MSPR:</strong>
                {{
                  productDialog.product.msrp
                    ? "$" + productDialog.product.msrp.toFixed(2)
                    : "n/a"
                }}
              </div>
              <div>
                <strong>LINK:</strong>
                <a
                  :href="
                    'https://thorbroadcast.com/product/' +
                      productDialog.product.slug +
                      '.html' +
                      (productDialog.product.mId
                        ? '/' + productDialog.product.mId
                        : '')
                  "
                  target="_blank"
                  >click</a
                >
              </div>
            </div>
            <v-card
              v-if="activeEan"
              outlined
              class="d-flex align-center px-1 justify-space-between my-3"
            >
              <div>Selected barcode:</div>
              <v-divider vertical class="mx-3" />
              <div>
                <strong>{{
                  productDialog.product.eans.find(x => x.ean === activeEan).name
                }}</strong>
              </div>
              <v-divider vertical class="mx-3" />
              <div>{{ activeEan }}</div>
              <v-divider vertical class="mx-3" />
              <v-btn
                v-if="productDialog.product.eans.length > 1"
                color="red"
                dark
                depressed
                small
                @click="activeEan = null"
                class="my-2"
              >
                <v-icon>mdi-close</v-icon>
                Uncheck barcode
              </v-btn>
            </v-card>
          </div>
        </v-sheet>
        <v-sheet class="pa-5" color="grey lighten-2">
          <v-alert v-if="productShelfs.length === 0" color="red lighten-1" dark>
            There is no "{{ productDialog.product.pName }}" in
            {{
              productDialogScannedShelf
                ? productDialogScannedShelf.name + " shelf"
                : "any shelf"
            }}. Scan shelf to add product or use button below.
          </v-alert>
          <div v-else>
            <div v-if="activeEan">
              <div
                v-for="(s, sk) in productShelfs.filter(
                  x => x.ean === activeEan
                )"
                :key="sk"
              >
                <v-card outlined flat class="mb-1">
                  <v-card-text>
                    <div class="d-flex">
                      <ShelfName
                        :shelf="allShelfs.filter(x => x.id === s.id)[0]"
                        class="mr-3"
                      />
                      ({{ shelfBarcodeDesc(productDialog.product, s.ean) }})
                      <v-spacer />
                      <ShelfQty
                        v-model="s.qty"
                        @input="
                          productChangeShelf(
                            productDialog.product,
                            s,
                            s.qty,
                            'set',
                            s.ean
                          )
                        "
                      />
                      <v-btn icon title="Move to" small @click="moveItem(s)">
                        <v-icon>mdi-arrow-u-right-top-bold</v-icon>
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </div>
            <div v-else-if="productDialogScannedShelf">
              <div
                v-if="
                  productDialog.product.shelfs.filter(
                    x => x.id === productDialogScannedShelf.id
                  ).length
                "
              >
                Barcodes in <ShelfName :shelf="productDialogScannedShelf" />
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>Barcode</th>
                      <th>Name</th>
                      <th>Qty</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(ps, psk) in productDialog.product.shelfs.filter(
                        x => x.id === productDialogScannedShelf.id
                      )"
                      :key="psk"
                    >
                      <td>{{ ps.ean }}</td>
                      <td>
                        {{
                          (
                            productDialog.product.eans.find(
                              x => x.ean === ps.ean
                            ) || { name: "" }
                          ).name
                        }}
                      </td>
                      <td>{{ ps.qty }}</td>
                      <td>
                        <v-btn
                          color="blue"
                          dark
                          small
                          @click="activeEan = ps.ean"
                          >SELECT</v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>
              <div v-else>
                This shelf is empty
              </div>
              <div class="d-flex align-center justify-space-between mt-5">
                <div>Select different barcode:</div>
                <div>
                  <v-select
                    hide-details
                    dense
                    @change="
                      (ean => {
                        if (!ean) {
                          return;
                        }
                        productChangeShelf(
                          productDialog.product,
                          allShelfs.find(
                            x => x.id == productDialogScannedShelf.id
                          ),
                          0,
                          'add',
                          ean
                        );
                        activeEan = ean;
                      })(arguments[0])
                    "
                    :items="
                      productDialog.product.eans.filter(
                        y =>
                          !productDialog.product.shelfs
                            .filter(x => x.id === productDialogScannedShelf.id)
                            .map(x => x.ean)
                            .includes(y.ean)
                      )
                    "
                    item-value="ean"
                    :item-text="i => (i.name ? i.name + ': ' : '') + i.ean"
                  />
                </div>
              </div>
            </div>
            <div v-else>
              <div class="text-center red--text">
                This product has more than one barcode. Choose the right one.
              </div>
              <div class="d-flex align-center justify-center my-10">
                <div class="mr-10">Select product:</div>
                <div>
                  <v-select
                    v-model="activeEan"
                    hide-details
                    dense
                    :items="productDialog.product.eans"
                    item-value="ean"
                    :item-text="i => (i.name ? i.name + ': ' : '') + i.ean"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="text-right mt-5">
            <v-btn color="green" dark small @click="manuallyAdd.show = true">
              <v-icon>mdi-plus</v-icon>
              Add
            </v-btn>
          </div>
        </v-sheet>
      </div>
    </v-dialog>
    <v-dialog v-model="newProduct.show" max-width="600px" persistent>
      <v-toolbar color="blue" dark>
        <v-toolbar-title>New product</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="newProduct.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-sheet class="pa-5">
        <v-alert color="orange lighten-4" dense>
          Adding new product by this form is not recommended. Please add new
          products by admin panel if you can.
        </v-alert>
        <v-text-field v-model="newProduct.data.pName" label="Product name" />
        <v-text-field v-model="newProduct.data.pn" label="PN" />
        <div class="d-flex align-center">
          <v-text-field
            v-model="newProduct.data.ean"
            label="EAN"
            hide-details
          />
          <v-btn icon @click="newProduct.data.ean = generateEan()">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
        <div class="mt-3">
          Photo:
          <v-file-input v-model="newProduct.data.file" />
        </div>
        <v-btn @click="addNewProduct">Save</v-btn>
      </v-sheet>
    </v-dialog>
    <v-dialog v-model="manuallyAdd.show" max-width="600px" persistent>
      <v-toolbar color="blue" dark>
        <v-toolbar-title>Manually add to shelf</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="manuallyAdd.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-sheet class="pa-5">
        <div
          v-if="productDialog.product && productDialog.product.eans.length > 0"
          class="mb-3"
        >
          <strong>Select barcode</strong>
          <v-select
            v-model="activeEan"
            :item-text="item => (item.name ? item.name + ': ' : '') + item.ean"
            item-value="ean"
            :items="productDialog.product.eans"
            label="Barcode"
            clearable
          />
        </div>
        <strong>Select shelf</strong>
        <v-select
          v-model="manuallyAdd.shelf"
          :items="allShelfs"
          :item-text="
            item =>
              (item.parent.name ? item.parent.name + ' -> ' : '') + item.name
          "
          item-value="id"
          label="Shelf"
        >
        </v-select>
        <v-btn
          @click="
            productChangeShelf(
              productDialog.product,
              allShelfs.filter(x => x.id == manuallyAdd.shelf)[0],
              0,
              'add',
              activeEan
            );
            manuallyAdd.show = false;
          "
          >Add</v-btn
        >
      </v-sheet>
    </v-dialog>
    <v-dialog v-model="moveDialog.show" max-width="600px" persistent>
      <v-toolbar color="blue" dark>
        <v-toolbar-title>Move product to shelf</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="moveDialog.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-sheet class="pa-5">
        <div v-if="moveDialog.item" class="mb-3">
          <v-select
            v-model="moveDialog.to"
            :items="allShelfs"
            :item-text="
              item =>
                (item.parent.name ? item.parent.name + ' -> ' : '') + item.name
            "
            item-value="id"
            label="Shelf"
          >
          </v-select>
          <v-btn v-if="moveDialog.to" @click="moveClick">Move</v-btn>
        </div>
      </v-sheet>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../globals";
import functions from "../mixins/functions";
import QInput from "../components/QInput";
import ShelfQty from "../components/ShelfQty";
import ShelfName from "../components/ShelfName";

export default {
  name: "Products",
  components: { ShelfName, ShelfQty, QInput },
  mixins: [globals, functions],
  data() {
    return {
      filters: {
        onlyAdded: false,
        withoutBarcode: false,
        notFromThor: false,
        withHidden: false
      },
      q: null,
      products: [],
      page: 1,
      perPage: 50,
      changeBarcode: {
        product: null,
        show: false
      },
      selectedProducts: [],
      printDialog: false,
      productDialog: {
        show: false,
        product: null,
        scannedShelf: null,
        scannedQty: 0
      },
      shelfs: [],
      newProduct: {
        show: false,
        data: this.newProductBase()
      },
      manuallyAdd: {
        shelf: null,
        show: false
      },
      activeEan: null,
      moveDialog: {
        show: false,
        item: null,
        to: null
      }
    };
  },
  methods: {
    moveClick() {
      const shelf = this.productDialog.product.shelfs.find(
        x => x.id === this.moveDialog.to && x.ean === this.moveDialog.item.ean
      );
      if (shelf) {
        const q = this.moveDialog.item.qty;
        this.moveDialog.item.qty = 0;
        shelf.qty += q;
      } else {
        const s = this.allShelfs.find(x => x.id === this.moveDialog.to);
        this.moveDialog.item.id = s.id;
        this.moveDialog.item.name = s.name;
        this.moveDialog.item.barcode = s.barcode;
      }

      this.moveDialog.show = false;

      axios
        .post(
          this.globals.apiUrl +
            "/product/" +
            this.productDialog.product.id +
            "/saveShelfs",
          this.productDialog.product.shelfs
        )
        .then(() => {
          this.notyf("Saved!");
        });
    },
    moveItem(item) {
      this.moveDialog.item = item;
      this.moveDialog.show = true;
    },
    changeBuyPrice(product) {
      let price = prompt("Enter buy price", product.buyPrice || "");
      if (price !== null) {
        price = price.replace(/,/g, ".");
        price = price.replace(/[^\d.]+/g, "");
        if (/^\d+(\.\d+)?$/g.test(price)) {
          price = parseFloat(price);
        } else {
          price = null;
        }
        product.buyPrice = price;
        this.changeProduct(product, "buyPrice");
      }
    },
    shelfBarcodeDesc(product, ean) {
      let text = ean;
      const filtered = product.eans.filter(x => x.ean === ean);
      if (filtered.length > 0 && filtered[0].name) {
        text = filtered[0].name + ": " + text;
      }
      return text;
    },
    removeProduct(product) {
      this.$store.commit("confirm", {
        confirmFn: () => {
          axios
            .post(this.globals.apiUrl + "/product/remove", product)
            .then(() => {
              this.products.forEach((value, index) => {
                if (value.id === product.id) {
                  this.products.splice(index, 1);
                }
              });
            })
            .catch(() => {
              this.notyf("Error", "error");
            });
        }
      });
    },
    changePn(product) {
      let pn = prompt(
        "Enter new PN. Warning: It also changes on the page thorbroadcast.com",
        product.pn
      );
      if (pn === null) {
        return;
      }
      pn = pn.trim();
      axios.post(this.globals.apiUrl + "/product/changePn", {
        id: product.id,
        pn: pn
      });
    },
    getRandomEan(ean) {
      axios
        .get(this.globals.apiUrl + "/ean/list?randomFree")
        .then(res => {
          ean.ean = res.data.ean;
          ean.eanGenerated = true;
        })
        .catch(reason => {
          this.notyf(reason.response.data.message, "error");
        });
    },
    addNewProductSave(d) {
      d.file = undefined;
      this.notyf("Saving");
      axios.post(this.globals.apiUrl + "/product/addNew", d).finally(() => {
        window.location.reload();
      });
    },
    addNewProduct() {
      const d = {
        ...this.newProduct.data,
        image: null
      };
      if (d.file) {
        const fr = new FileReader();
        fr.onloadend = () => {
          d.image = fr.result;
          this.addNewProductSave(d);
        };
        fr.readAsDataURL(d.file);
      } else {
        this.addNewProductSave(d);
      }
    },
    newProductBase() {
      return {
        id: null,
        pName: "",
        pn: "",
        ean: this.generateEan(),
        file: null
      };
    },
    productChangeShelf(product, shelf, qty, type, ean) {
      if (!shelf) {
        if (product.shelfs.length === 0) {
          this.notyf("The product has no shelf.", "error");
          return;
        }
        if (product.shelfs.length > 1) {
          this.notyf("The product has more than one shelf.", "error");
          return;
        }
        this.productDialog.scannedShelf = product.shelfs[0].barcode;
        shelf = this.productDialogScannedShelf;
        this.notyf("Shelf automatically assigned.", "success");
      }
      if (!ean || product.eans.filter(x => x.ean === ean).length === 0) {
        this.notyf("Please select one product barcode", "error");
        return;
      }
      const filtered = product.shelfs.filter(
        x => shelf.id === x.id && x.ean === ean
      );
      if (filtered.length === 1) {
        const old = filtered[0].qty;
        filtered[0].qty = type === "add" ? filtered[0].qty + qty : qty;
        if (filtered[0].qty < 0) filtered[0].qty = 0;
        this.productDialog.scannedQty += filtered[0].qty - old;
      } else {
        product.shelfs.push({
          id: shelf.id,
          name: shelf.name,
          barcode: shelf.barcode,
          qty: qty < 0 ? 0 : qty,
          ean: ean
        });
        if (qty > 0) {
          this.productDialog.scannedQty += qty;
        }
      }
      axios
        .post(
          this.globals.apiUrl + "/product/" + product.id + "/saveShelfs",
          product.shelfs
        )
        .then(() => {
          this.notyf("Saved!");
        });
    },
    barcodeScanned(code) {
      if (this.productDialog.show) {
        if (["ADD", "SUBTRACT", "CLEAR"].includes(code)) {
          if (code === "CLEAR") {
            this.productChangeShelf(
              this.productDialog.product,
              this.productDialogScannedShelf,
              0,
              "set",
              this.activeEan
            );
          } else {
            const dir = code === "ADD" ? 1 : -1;
            this.productChangeShelf(
              this.productDialog.product,
              this.productDialogScannedShelf,
              dir,
              "add",
              this.activeEan
            );
          }
        } else if (this.allShelfsBarcodes.includes(code)) {
          this.productDialog.scannedShelf = code;
          this.productChangeShelf(
            this.productDialog.product,
            this.productDialogScannedShelf,
            0,
            "add",
            this.activeEan
          );
        } else {
          this.q = code;
        }
      } else if (this.changeBarcode.show) {
        // nothing
      } else if (this.allShelfsBarcodes.includes(code)) {
        this.$router.push({
          query: { shelf: this.allShelfs.filter(x => x.barcode == code)[0].id }
        });
      } else {
        this.q = null;
        this.q = code;
      }
    },
    showProduct(product) {
      this.productDialog.scannedShelf = null;
      this.productDialog.product = product;
      this.productDialog.show = true;
      this.productDialog.scannedQty = 0;
      const eans = product.eans.map(x => x.ean);
      if (this.activeEan && !eans.includes(this.activeEan)) {
        this.activeEan = null;
      }
      if (!this.activeEan && eans.length === 1) {
        this.activeEan = eans[0];
      }
    },
    prnt() {
      const arr = [];
      this.selectedProducts.forEach(x =>
        x.eans.forEach(e =>
          arr.push({
            b: e.ean,
            q: e.printQty,
            n: (x.pn + " " + e.name).trim()
          })
        )
      );
      this.printBarcodes(arr, "ean13");
      this.selectedProducts = [];
    },
    showPrintDialog() {
      this.selectedProducts.forEach(x =>
        x.eans.forEach(y => this.$set(y, "printQty", 1))
      );
      this.printDialog = true;
    },
    changeProduct(product, field) {
      axios.post(this.globals.apiUrl + "/product/save", {
        id: product.id,
        [field]: product[field]
      });
    },
    changeBarcodeSave() {
      axios
        .post(this.globals.apiUrl + "/product/save", {
          id: this.changeBarcode.product.id,
          eans: this.changeBarcode.product.eans
        })
        .then(res => {
          this.changeBarcode.product.eans = res.data.eans;
          this.changeBarcode.product.shelfs = res.data.shelfs;
          this.changeBarcode.show = false;
        });
    }
  },
  watch: {
    q(val) {
      this.page = 1;
      if (val && /^[0-9]+$/g.test(val)) {
        this.activeEan = val.replace(/^0+/g, "");
      }
    },
    "changeBarcode.show": {
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.$refs.changeBarcode.focus();
          }, 500);
        }
      }
    }
  },
  computed: {
    productShelfs() {
      return this.productDialog.product.shelfs.filter(x =>
        this.productDialogScannedShelf
          ? x.id === this.productDialogScannedShelf.id
          : true
      );
    },
    filterShelf() {
      if (!this.$route.query.shelf) {
        return null;
      }
      for (const i in this.shelfs) {
        const shelf = this.shelfs[i];
        if (shelf.id == this.$route.query.shelf) {
          return shelf;
        }

        for (const j in shelf.children) {
          const child = shelf.children[j];
          if (child.id == this.$route.query.shelf) {
            return child;
          }
        }
      }
      return null;
    },
    productDialogScannedShelf() {
      if (!this.productDialog.scannedShelf) {
        return null;
      }
      for (const i in this.shelfs) {
        if (this.shelfs[i].barcode == this.productDialog.scannedShelf) {
          return this.shelfs[i];
        }
        for (const j in this.shelfs[i].children) {
          if (
            this.shelfs[i].children[j].barcode ==
            this.productDialog.scannedShelf
          ) {
            return this.shelfs[i].children[j];
          }
        }
      }
      return null;
    },
    allShelfs() {
      const arr = [];
      this.shelfs.forEach(s => {
        arr.push(s);
        s.children.forEach(c => {
          arr.push(c);
        });
      });
      return arr;
    },
    allShelfsBarcodes() {
      return this.allShelfs.map(x => x.barcode);
    },
    filteredProducts() {
      const filtered = this.products
        .filter(product => {
          let q = (this.q || "").trim().replace(/^0+/g, "");
          if (q.length === 0) return true;
          if (q.includes("/product/") && q.includes(".html")) {
            const matches = /\.html\/(\d+)/.exec(q);
            if (matches) {
              return product.mId == matches[1];
            }

            q = q.split("/product/")[1];
            q = q.split(".html")[0];
          }
          const searchStr =
            product.pName +
            product.mName +
            product.pn +
            product.eans.map(x => x.ean).join("") +
            product.slug;
          return searchStr.toLowerCase().includes(q.toLowerCase());
        })
        .filter(product => {
          if (!this.filterShelf) return true;

          const ids = [this.filterShelf.id];
          this.filterShelf.children.forEach(x => ids.push(x.id));

          return (
            product.shelfs.filter(x => ids.includes(x.id) && x.qty > 0).length >
            0
          );
        })
        .filter(product => {
          if (!this.filters.onlyAdded) {
            return true;
          }

          return product.shelfs.filter(x => x.qty > 0).length > 0;
        })
        .filter(product => {
          if (!this.filters.withoutBarcode) {
            return true;
          }

          return !product.ean;
        })
        .filter(product => {
          if (!this.filters.notFromThor) {
            return true;
          }

          return !product.fromThor;
        })
        .filter(product => {
          return this.filters.withHidden ? true : !product.hidden;
        })
        .map((x, i) => {
          x.lp = i + 1;
          return x;
        });

      if (this.q && filtered.length === 1) {
        this.showProduct(filtered[0]);
      } else if (this.q && this.productDialog.show && filtered.length === 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.productDialog.show = false;
      }

      return filtered;
    },
    filteredProductsPage() {
      return this.filteredProducts.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },
    pages() {
      return Math.ceil(this.filteredProducts.length / this.perPage);
    }
  },
  async created() {
    this.$store.commit("showLoading");
    const promises = [
      axios.get(this.globals.apiUrl + "/product/list"),
      axios.get(this.globals.apiUrl + "/shelf/list")
    ];
    const results = await Promise.all(promises);
    this.products = results[0].data;
    this.shelfs = results[1].data;
    window._bc.handler = this.barcodeScanned;
    this.$store.commit("hideLoading");
  }
};
</script>

<style scoped lang="scss"></style>
