<template>
  <v-text-field clearable placeholder="Search..." prepend-inner-icon="mdi-magnify" solo outlined hide-details dense flat v-model="newValue" @input="inp"/>
</template>

<script>
export default {
  name: "QInput",
  props: ['value'],
  data () {
    return {
      newValue : this.value,
      debounce: null
    }
  },
  watch: {
    value (val) {
      this.newValue = val
    }
  },
  methods: {
    inp () {
      if (this.debounce) clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.$emit('input', this.newValue)
      }, 300)
    }
  }
}
</script>

<style scoped>

</style>
