<template>
  <div class="d-flex">
    <v-btn small depressed color="red" dark @click="changeVal(-1)" class="mx-1 px-2">
      <v-icon>mdi-minus</v-icon>
      few
    </v-btn>
    <v-btn small depressed color="red lighten-2" dark @click="changeVal(-1, 1)" class="mx-1 px-2">
      <v-icon>mdi-minus</v-icon>
      1
    </v-btn>
    <input min="0" v-model="newValue" @change="inp" class="nbr">
    <v-btn small depressed color="green lighten-2" dark @click="changeVal(1, 1)" class="mx-1 px-2">
      <v-icon>mdi-plus</v-icon>
      1
    </v-btn>
    <v-btn small depressed color="green" dark @click="changeVal(1)" class="mx-1 px-2">
      <v-icon>mdi-plus</v-icon>
      few
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "ShelfQty",
  props: ['value'],
  data () {
    return {
      newValue: this.value
    }
  },
  watch: {
    value (val) {
      this.newValue = val
    }
  },
  methods: {
    changeVal(dir, n) {
      if (!n) {
        n = prompt('Enter value to ' + (dir > 0 ? 'add' : 'subtract'), 1) || 0
        if (isNaN(n)) {
          return
        }
        n = parseInt(n)
        if (n <= 0) {
          return
        }
      }
      let nv = parseInt(this.newValue) + n * dir
      if (nv < 0) {
        nv = 0
      }
      this.newValue = nv
      this.$emit('input', nv)
    },
    inp () {
      this.newValue = this.newValue.trim()
      if(!this.newValue || isNaN(this.newValue))
      {
        this.newValue = 0
      }
      this.newValue = parseInt(this.newValue)
      if(this.newValue < 0) {
        this.newValue = 0
      }
      this.$emit('input', this.newValue)
    }
  }
}
</script>

<style scoped lang="scss">
input.nbr {
  max-width: 100px;
  text-align: center;
  border: 1px solid #777;
}
</style>
